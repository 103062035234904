<template>
	<div>
		<v-row>
			<v-col cols="5">
				<s-user 
					@input="changeUserSelected" 
					:isCrud="isCrud"
				/>
			</v-col>
			<v-col cols="7">
				<s-toolbar
					label="Parámetros"
					color="grey"
					dark
					searchInput
					@textSearch="textSearch($event)" 
				></s-toolbar>
				<v-data-table
					:headers="headerParameters"
					v-model="itemsParameterSelected"
					dense
					:items="itemsParameter"
					show-select
					item-key="PrmID"
					@item-selected="clickReportSelected($event)"
				>

				</v-data-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import sUser from "@/views/Security/UserOption/User.vue";
    import _sParameter from "@/services/General/ParameterService";
    import _sParameterPermissionUser from "@/services/General/ParameterPermissionUserService";

	export default {
		components: {
			sUser,
		},

		data() {
			return {
				isCrud: false,
				itemsParameter : [],
				headerParameters : [
                    {text : 'ID', value : 'PrmID' },
                    {text : 'Parámetro', value : 'PrmName' },
                    {text : 'Descripción', value : 'PrmDescription', width: '20%'},
                    {text : 'Valor', value : 'PrmValue'},
                ],
				filter: {},
				reportPermission : {},
				userSelected :{},
				itemsParameterSelected : [],
				UsrID : 0
			}
		},
		

		methods: {
			textSearch(text){
                this.filter.Search = text;
                this.loadChanges();
            },
			loadChanges(){
                this.getListParameters();
            },
			changeUserSelected(user) {
				if(user.UsrID != undefined)
				{
					this.UsrID = user.UsrID
				}
				this.userSelected = user;
				this.getListParametersByUser();
				this.getListParameters();
			},

			getListParameters()
			{
				_sParameter
				.listoptions(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsParameter = resp.data;
						//console.log('quersss', this.itemsParameter);
					}
				})
			},
			clickReportSelected(report)
			{
				this.reportPermission.PrmID = report.item.PrmID;
				this.reportPermission.UsrID = this.userSelected[0].UsrID;
				this.reportPermission.SecStatus = 1;
				this.reportPermission.UsrCreateID = this.$fun.getUserID();
				this.reportPermission.UsrUpdateID = this.$fun.getUserID();

				_sParameterPermissionUser
				.save(this.reportPermission, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Guardado Correctamente", "success");
						return;
					}
				})
			},
			getListParametersByUser()
			{
				if(this.userSelected[0] != undefined)
				{
					_sParameterPermissionUser
					.list({
						UsrID : this.userSelected[0].UsrID
					}, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							this.itemsParameterSelected = resp.data;
						}
					})
				}
			}
		},

		created () {
			this.getListParameters();
		},
	}
</script>
